.small-image {
  margin: 2px;
  max-width: 300px;
  height: auto;
  border-radius: 20%;
}

.back-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
}

.logo-image {
  max-width: 150px;
  height: auto;
}

.navbar-shadow {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.navbar-light .navbar-nav .nav-link {
  color: #000;
}

.navbar-light .navbar-nav .nav-link:hover {
  color: #555;
}

.gradient-bg {
  background: linear-gradient(
      to right,
      rgba(0, 123, 255, 0.8),
      rgba(7, 137, 101, 1.8)
    ),
    url("../public/assets/cathlab.jpg"); /* Gradien dari biru ke putih dengan gambar latar */
  background-size: cover; /* Ukuran gambar latar */
  background-position: center; /* Posisi gambar latar */
  border-bottom-right-radius: 150px; /* Efek rounded di bagian bawah kanan */
  height: 100%; /* Tinggi penuh */
  display: flex;
  padding: 50px;
  margin-right: 10px; /* Padding untuk konten di dalamnya */
  opacity: 95%;
  margin-left: -20px;
}

.xlimus-bg {
  background: url("../public/assets/XLIMUS-1.png");
  background-size: cover; /* Ukuran gambar latar */
  background-position: center; /* Posisi gambar latar */
  background-repeat: no-repeat; /* Jangan ulangi gambar latar */
  width: 100%; /* Lebar penuh layar */
  height: 100%; /* Tinggi penuh layar */
  display: flex;
  justify-content: center;
  align-items: center; /* Centering content */
}

.bg-color {
  background-color: #078965;
  border: none;
  color: white;
  padding: 8px 15px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 15px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 5px;
}
.typing-text {
  min-height: 25px;
  margin-bottom: 0;
}
.about {
  text-align: justify;
  word-wrap: break-word;
}
footer {
  background-color: #343a40;
  color: white;
  padding: 20px 0;
}

footer h5 {
  margin-bottom: 20px;
}

footer a {
  color: white;
  text-decoration: none;
}

footer a:hover {
  text-decoration: underline;
}

footer .d-flex a {
  margin-right: 15px;
}

footer .d-flex a:last-child {
  margin-right: 0;
}

.contact-info .justify-text {
  text-align: justify;
}
/* App.css */
.full-height {
  min-height: 550px;
  align-content: center; /* Set minimum height sesuai kebutuhan */
}
.full-height-about {
  min-height: 550px;
  align-content: center; /* Set minimum height sesuai kebutuhan */
}
.full-height-xlimus {
  position: relative;
  min-height: 700px;
  overflow: hidden;
}

.vertical-line {
  border-left: 4px solid #078965; /* Warna dan ketebalan garis */
  height: 250px; /* Tinggi penuh */
  margin: 0 auto; /* Pusatkan garis di dalam kolom */
}
.img-principle {
  align-content: center;
  width: 200px;
  object-fit: cover;
  aspect-ratio: 3/2;

  mix-blend-mode: darken;
}
.img-cert {
  display: block; /* Ensure the image is displayed as a block-level element */
  margin: auto; /* Center the image horizontally */
  width: 190px; /* Set the width of the image */
  aspect-ratio: 4/3; /* Maintain aspect ratio */
  height: auto; /* Set the blend mode */
  /* Apply a drop shadow */
}
.img-about {
  max-width: 100%;
  height: auto;
  aspect-ratio: 4/2;
}
.img-all {
  background-position: center; /* Posisi gambar latar */
  border-radius: 50px;
  max-width: 100%; /* Tinggi penuh */
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}
.card-body {
  height: 300px;
  align-content: center;
  /* Atur tinggi yang diinginkan */
}

.card-center {
  display: flex;
  justify-content: center;
}
.card-hover:hover {
  transform: scale(1.1); /* Memperbesar kartu ketika dihover */
  transition: transform 0.3s ease; /* Transisi untuk efek yang mulus */
}

.card-hover:not(:hover) {
  transform: scale(0.9); /* Mengurangi ukuran kartu yang tidak dihover */
  transition: transform 0.3s ease; /* Transisi untuk efek yang mulus */
}

.slick-slide {
  padding: 10px; /* Tambahkan padding agar tidak menempel satu sama lain */
}

.slick-dots li button:before {
  color: black; /* Warna dots */
}
.size-font {
  font-size: clamp(20px, 6vw, 60px);
}
.line {
  border-bottom: 4px solid rgba(0, 123, 255, 0.8);
}
.icon-container {
  font-size: 50px; /* Sesuaikan ukuran ikon */
  margin-bottom: 10px;
}
.fixed-size-card {
  border-radius: 15px;
  width: 100%;
  height: 100%;
  max-width: 300px; /* Set a maximum width for the card */
  min-height: 400px;
  box-shadow: 0 15px 15px rgba(0, 0, 0, 0.1); /* Shadow properties */
  transition: transform 0.2s; /* Set a minimum height for the card */
}
.fixed-size-card-product {
  border-radius: 15px;
  width: 300px;
  height: 100%;
  max-width: 300px; /* Set a maximum width for the card */
  min-height: 400px; /* Set a minimum height for the card */
  box-shadow: 0 15px 15px rgba(0, 0, 0, 0.1); /* Shadow properties */
  transition: transform 0.2s; /* Optional: for smooth hover effect */
}
.coronary-card {
  width: 100%;
  height: auto;
  max-width: 290px; /* Set a maximum width for the card */
  min-height: 290px;
  border-radius: 20px;
}
.fullscreen-image {
  width: 100%;
  height: 800px;
  top: 0;
  left: 0;
  right: 0;
}
.slick-slide {
  display: flex;
  justify-content: center;
  align-items: center;
}

.img-hospital {
  align-content: center;
  max-width: 100%; /* Gambar akan menyesuaikan lebar maksimal parentnya */
  max-height: 100%; /* Gambar akan menyesuaikan lebar maksimal parentnya */
  height: 150px;
  mix-blend-mode: darken;
}
.ecatalogue {
  top: 30px;
  right: 30px;
  width: 100px;
  height: auto;
  z-index: 1;
}
.ecatalogue2 {
  top: 20px;
  left: 30px;
  width: 100px;
  height: auto;
  z-index: 1;
}

.news-content {
  justify-content: center;
  font-size: 1.1rem;
  color: #333;
  line-height: 1.6;
  margin-bottom: 20px;
  word-wrap: break-word; /* Word-wrap for long text */
  overflow-wrap: break-word;
  text-align: justify;
}

.news-content p {
  margin-bottom: 15px;
}

.news-content ul {
  margin: 0;
  padding: 0 0 0 20px;
  list-style-type: disc;
}

.news-content li {
  margin-bottom: 5px;
}

.news-content br {
  display: block;
  margin-bottom: 10px;
}
.active {
  background-color: #13395e;
  color: #b6c8d9;
}
